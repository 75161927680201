import './app.scss';
import 'lazysizes';
import htmx from 'htmx.org';
import header from './ts/header';
import swiper from './ts/swiper';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const accordionSections = document.querySelectorAll('.accordion-section') as NodeListOf<HTMLElement>;
    if (accordionSections.length) {
        import('./ts/accordion').then((component) => {
            accordionSections.forEach(function (section) {
                component.default.init(section);
            });
        });
    }

    const accordionImageSections = document.querySelectorAll('.accordion-image-section') as NodeListOf<HTMLElement>;
    if (accordionImageSections.length) {
        import('./ts/accordion-image').then((component) => {
            accordionImageSections.forEach(function (section) {
                component.default.init(section);
            });
        });
    }

    const sprigComponents = document.querySelectorAll('.sprig-component') as NodeListOf<HTMLElement>;
    if (sprigComponents.length) {
        import('./ts/filter-buttons').then((component) => {
            sprigComponents.forEach(function (section) {
                component.default.init(section);
            });
        });
    }

    const priceCardSliders = document.querySelectorAll('.pricing-card-slider') as NodeListOf<HTMLElement>;
    if (priceCardSliders.length) {
        import('./ts/price-card-slider').then((slider) => {
            priceCardSliders.forEach(function (section) {
                slider.default.init(section);
            });
        });
    }

    const menu = document.getElementById('header-menu');
    if (menu) {
        header.init();
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        allSwiper.forEach(function (swiperElement) {
            if (!swiperElement.dataset.swiperOptions) return;
            // Set swiper element and swiper options from data-attribute
            swiper.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
        });
    }

    const forms = document.querySelectorAll('div[data-form-id]');
    if (forms.length) {
        import('./ts/form').then((form) => {
            form.default.init();
        });
    }

    const events = ['click', 'mousemove', 'keydown', 'wheel', 'touchstart'];

    function userAction() {
        // no element request because element will be generated via js, only used once
        import('./ts/cookie-banner').then((cookieBanner) => {
            cookieBanner.default.init();
        });

        // Remove all listeners after the first action
        events.forEach((e) => document.removeEventListener(e, userAction));
    }

    // Attach event listeners to detect first user interaction
    events.forEach((e) => document.addEventListener(e, userAction));

    const youtubeVideoContainers = document.querySelectorAll('.youtube-video-container') as NodeListOf<HTMLElement>;
    if (youtubeVideoContainers.length) {
        import('./ts/youtube-video').then((youtubeVideo) => {
            youtubeVideoContainers.forEach((container) => {
                youtubeVideo.default.init(container);
            });
        });
    }

    htmx.on('htmx:afterSwap', function () {
        // recreate js after sprig reloads something
        const sprigComponents = document.querySelectorAll('.sprig-component') as NodeListOf<HTMLElement>;
        if (sprigComponents.length) {
            import('./ts/filter-buttons').then((component) => {
                sprigComponents.forEach(function (section) {
                    component.default.init(section);
                });
            });
        }

        const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
        if (allSwiper.length) {
            import('./ts/swiper').then((swiper) => {
                allSwiper.forEach(function (swiperElement) {
                    if (!swiperElement.dataset.swiperOptions) return;
                    // Set swiper element and swiper options from data-attribute
                    swiper.default.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
                });
            });
        }
    });
})();
