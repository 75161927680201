import { lock, clearBodyLocks } from 'tua-body-scroll-lock';

export default {
    init() {
        // Stops menu from animating on load
        if (document.body.classList.contains('menu-no-animation')) {
            document.body.classList.remove('menu-no-animation');
        }

        // Navigation
        const headerMenu = document.querySelector('.header-menu') as HTMLElement;
        const hamburger = document.getElementById('hamburger');
        const menuItems = document.querySelector('.header-menu-overlay-links') as HTMLElement;
        const backdropArea = document.querySelector('.backdrop-area');

        const contactOverlayTrigger = document.querySelector('#contact-overlay-trigger') as HTMLElement;
        const contactOverlay = document.querySelector('#contact-overlay') as HTMLElement;
        const contactOverlayTriggerMobile = document.querySelector('#contact-overlay-trigger-mobile') as HTMLElement;
        const contactOverlayMobile = document.querySelector('#contact-overlay-mobile') as HTMLElement;

        function updateMenuItemsHeight() {
            // Calculate height of menu items container
            if (window.matchMedia('screen and (max-width: 1279px)').matches) {
                const footerArea = document.querySelector('.footer-area') as HTMLElement;
                const footerHeight = footerArea ? footerArea.offsetHeight : 0;
                const headerHeight = 66;

                const menuItemsHeight = window.innerHeight - footerHeight - headerHeight;

                menuItems.style.height = menuItemsHeight + 'px';
            } else {
                // Reset height for larger screens
                menuItems.style.height = '';
            }
        }

        // Function to remove 'active' class from all menu-item-sub elements
        function removeActiveFromMenuItems() {
            const menuItemSub: NodeListOf<HTMLElement> = headerMenu.querySelectorAll('.menu-item-sub');
            menuItemSub.forEach((item) => {
                item.classList.remove('active');
            });
        }

        if (headerMenu && hamburger && menuItems) {
            // Initial height calculation
            updateMenuItemsHeight();

            hamburger.addEventListener('click', () => {
                headerMenu.classList.toggle('open');
                hamburger.classList.toggle('close');

                if (headerMenu.classList.contains('open')) {
                    lock(menuItems);
                } else {
                    clearBodyLocks();
                }
            });

            window.addEventListener('resize', () => {
                updateMenuItemsHeight();

                // If window is resized to the size where the burger menu appears (<= 1279px)
                if (window.matchMedia('screen and (max-width: 1279px)').matches) {
                    removeActiveFromMenuItems();
                }
            });

            const menuItemSub: NodeListOf<HTMLElement> = headerMenu.querySelectorAll('.menu-item-sub');

            if (menuItemSub.length) {
                menuItemSub.forEach((item) => {
                    const button: HTMLButtonElement | null = item.querySelector('button');

                    if (button) {
                        button.addEventListener('click', () => {
                            const activeMenuItemSub = headerMenu.querySelector('.menu-item-sub.active');
                            if (contactOverlay && contactOverlay.classList.contains('active')) {
                                contactOverlay.classList.remove('active');
                            }

                            if (contactOverlayMobile && contactOverlayMobile.classList.contains('active')) {
                                contactOverlayMobile.classList.remove('active');
                            }

                            if (window.innerWidth > 1279) {
                                item.classList.toggle('active');
                                // remove hidden from backdrop area
                                if (item.classList.contains('active')) {
                                    backdropArea?.classList.remove('hidden');
                                }
                            } else {
                                item.classList.toggle('active');
                            }

                            if (activeMenuItemSub) {
                                activeMenuItemSub.classList.remove('active');
                                backdropArea?.classList.add('hidden');
                            }
                        });
                    }
                });
            }

            // Check for active-tab class and set active state to parent menu item
            const submenuItems = document.querySelectorAll('.submenu-item');
            submenuItems.forEach((submenuItem) => {
                if (submenuItem.classList.contains('active-tab')) {
                    // get parent menu item with class submenu-item-button
                    const parentMenuItem = submenuItem.closest('.menu-item-sub') as HTMLElement;
                    const submenuButton = parentMenuItem?.querySelector('.submenu-item-button') as HTMLElement;
                    submenuButton?.classList.add('active-tab');
                }
            });

            // if backdropArea is clicked, add hidden class
            if (backdropArea) {
                backdropArea.addEventListener('click', () => {
                    // remove active class from menu items
                    const activeMenuItemSub = headerMenu.querySelector('.menu-item-sub.active');
                    if (activeMenuItemSub) {
                        activeMenuItemSub.classList.remove('active');
                    }
                    contactOverlay.classList.remove('active');
                    contactOverlayMobile.classList.remove('active');
                    backdropArea.classList.add('hidden');
                    clearBodyLocks();
                });
            }
        }

        // contact icon overlay
        if (contactOverlayTrigger && contactOverlay) {
            contactOverlayTrigger.addEventListener('click', () => {
                // remove active class from menu items
                const activeMenuItemSub = headerMenu.querySelector('.menu-item-sub.active');
                if (activeMenuItemSub) {
                    activeMenuItemSub.classList.remove('active');
                    contactOverlay.classList.toggle('active');
                    if (backdropArea?.classList.contains('hidden')) {
                        backdropArea?.classList.remove('hidden');
                    }
                } else {
                    contactOverlay.classList.toggle('active');
                    if (backdropArea?.classList.contains('hidden')) {
                        backdropArea?.classList.remove('hidden');
                    }
                }
            });

            if (contactOverlay.classList.contains('active')) {
                if (backdropArea?.classList.contains('hidden')) {
                    backdropArea?.classList.remove('hidden');
                }
            } else {
                backdropArea?.classList.add('hidden');
            }
        }

        // contact icon overlay mobile
        if (contactOverlayTriggerMobile && contactOverlayMobile) {
            contactOverlayTriggerMobile.addEventListener('click', () => {
                contactOverlayMobile.classList.toggle('active');
                if (backdropArea?.classList.contains('hidden')) {
                    backdropArea?.classList.remove('hidden');
                } else {
                    backdropArea?.classList.add('hidden');
                }

                // remove active class from menu items
                const activeMenuItemSub = headerMenu.querySelector('.menu-item-sub.active');
                if (activeMenuItemSub) {
                    activeMenuItemSub.classList.remove('active');
                }
            });

            if (contactOverlayMobile.classList.contains('active')) {
                if (backdropArea?.classList.contains('hidden')) {
                    backdropArea?.classList.remove('hidden');
                }
            } else {
                backdropArea?.classList.add('hidden');
            }
        }

        // // add scroll styling for header
        // window.onscroll = function () {
        //     if (document.documentElement.scrollTop > headerMenu.clientHeight) {
        //         headerMenu.classList.add('bg-scroll');
        //     } else {
        //         headerMenu.classList.remove('bg-scroll');
        //     }
        // };
    }
};
